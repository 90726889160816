<template>
  <div v-if="currentUser.superAdmin">
    <v-data-table
        :headers="headers"
        :items="memberships"
        :options.sync="pagination"
        :server-items-length="totalMemberships"
        :loading="loading"
        :footer-props="{
        'showFirstLastPage':true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
        class="elevation-1"
    >
      <template v-slot:item.id="{ item }">
        <v-menu :close-on-content-click=false bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon small class="mr-2" v-on="on"
            >mdi-focus-field-horizontal
            </v-icon>
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item>
              <v-list-item-title>{{ item.id }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.created="{ item }">
        {{ parseDate(item.created) }}
      </template>
      <template v-slot:item.validTo="{ item }">
        {{ parseDate(item.validTo) }}
      </template>
      <template v-slot:item.lastPaymentDate="{ item }">
        {{ parseDate(item.lastPaymentDate) }}
      </template>
      <template v-slot:item.active="{ item }">
        <v-switch
            v-model="item.active" disabled
        ></v-switch>
      </template>
      <template v-slot:item.haveRedeems="{ item }">
        {{ item.haveRedeems ? 'Yes' : 'No' }}
      </template>
      <template v-slot:item.autoRenewal="{ item }">
        <v-switch
            v-model="item.autoRenewal" disabled
        ></v-switch>
      </template>
      <template v-slot:item.invoiceUrl="{ item }">
        <v-btn v-if="item.invoiceUrl != null"
               class="ma-2"
               color="blue lighten-2"
               dark
               :href="item.invoiceUrl"
        >
          <v-icon dark>
            mdi-receipt
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.invoiceStatus="{ item }">
        <v-chip
            class="ma-2"
            color="green"
            text-color="white"
            v-if="item.invoiceStatus === 'SUCCESS'"
        >
          {{ item.invoiceStatus }}
        </v-chip>
        <v-chip
            class="ma-2"
            color="red"
            text-color="white"
            v-if="item.invoiceStatus === 'ERROR'"
        >
          {{ item.invoiceStatus }}
        </v-chip>
        <v-chip
            class="ma-2"
            color="lime darken-1"
            text-color="white"
            v-if="item.invoiceStatus === 'PENDING'"
        >
          {{ item.invoiceStatus }}
        </v-chip>
        <v-chip
            class="ma-2"
            color="orange darken-1"
            text-color="white"
            v-if="item.invoiceStatus === 'CANCELED'"
        >
          {{ item.invoiceStatus }}
        </v-chip>
        <v-chip
            class="ma-2"
            color="blue-grey darken-1"
            text-color="white"
            v-if="item.invoiceStatus === 'STALLED'"
        >
          {{ item.invoiceStatus }}
        </v-chip>
        {{
          item.invoiceStatus != 'SUCCESS' && item.invoiceStatus != 'ERROR' && item.invoiceStatus != 'PENDING' &&
          item.invoiceStatus != 'CANCELED' && item.invoiceStatus != 'STALLED' ? item.invoiceStatus : ""
        }}
      </template>
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-text-field v-model="shopId" type="text"
                          @keyup.enter="page = 1;retrieveMemberships();"></v-text-field>
          </td>
          <td>
            <v-text-field v-model="shopName" type="text"
                          @keyup.enter="page = 1;retrieveMemberships();"></v-text-field>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-datetime-picker
                v-model="createdDateTo"
                time-format="HH:mm:ss"
            ></v-datetime-picker>
          </td>
          <td>
            <v-datetime-picker
                v-model="validDateTo"
                time-format="HH:mm:ss"
            ></v-datetime-picker>
          </td>
          <td>
            <v-select
                :items="activeSubscriptionsList"
                v-model="activeSubscriptions"
                dense
                hide-details
                v-on:change="page = 1;retrieveMemberships();"
                @keyup.enter="page = 1;retrieveMemberships();"
            ></v-select>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-select
                :items="subscriptionIntervalList"
                v-model="subscriptionInterval"
                dense
                hide-details
                @keyup.enter="page = 1;retrieveMemberships();"
            ></v-select>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-row>
              <v-col>
                <v-select
                    :items="haveRedeemsList"
                    v-model="haveRedeems"
                    dense
                    hide-details
                    @keyup.enter="page = 1;retrieveMemberships();"
                ></v-select>
              </v-col>
              <v-col>
                <v-avatar color="indigo" size="30">
                  <v-icon
                      dark
                      @click="
                  page = 1;
                  retrieveMemberships();
                "
                  >mdi-magnify
                  </v-icon>
                </v-avatar>
              </v-col>
              <v-col>
                <v-avatar color="grey" size="30">
                  <v-icon
                      dark
                      @click="
                  page = 1;
                  clearSearch();
                "
                  >mdi-close
                  </v-icon>
                </v-avatar>
              </v-col>
            </v-row>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import SubscriptionApiService from "@/core/services/api.service.subscription";
import {mapGetters} from "vuex";

export default {
  name: "memberships",
  computed: {
    ...mapGetters(["currentUser"]),
    activeSubscriptionsList() {
      return [this.$i18n.t("common.all"), this.$i18n.t("common.no"), this.$i18n.t("common.yes"),]
    },
    headers() {
      return [
        {text: "Id", align: "start", value: "id", sortable: false},
        {text: this.$i18n.t('pages.membership.shopId'), value: "shopId", sortable: false},
        {text: this.$i18n.t('pages.membership.shopName'), value: "shopName", sortable: false},
        {text: this.$i18n.t('pages.membership.commission'), value: "commision", sortable: false},
        {text: this.$i18n.t('common.created'), value: "created", sortable: false},
        {text: this.$i18n.t('pages.membership.validTo'), value: "validTo", sortable: false},
        {text: this.$i18n.t('common.active'), value: "active", sortable: false},
        {
          text: this.$i18n.t('pages.membership.subscriptionName'),
          value: "subscriptionName",
          sortable: false
        },
        {
          text: this.$i18n.t('pages.membership.subscriptionTimeInterval'),
          value: "subscriptionTimeInterval",
          sortable: false
        },
        {
          text: this.$i18n.t('pages.membership.autoRenewal'),
          value: "autoRenewal",
          sortable: false
        },
        {text: this.$i18n.t('common.price'), value: "price", sortable: false},
        {
          text: this.$i18n.t('pages.membership.lastPaymentDate'),
          value: "lastPaymentDate",
          sortable: false
        },
        {text: this.$i18n.t('pages.membership.haveRedeems'), value: "haveRedeems", sortable: false},
      ]
    }
  },
  data() {
    return {
      pageSizes: [10, 20, 50, 100],
      validDateFrom: null,
      validDateTo: null,
      createdDateFrom: null,
      createdDateTo: null,
      totalMemberships: 0,
      memberships: [],
      loading: false,
      subscriptionApiInitiated: false,
      pagination: {},
      loader: null,
      activeSubscriptions: "",
      shopName: null,
      subscriptionIntervalList: ["MONTHLY", "YEARLY"],
      subscriptionInterval: "",
      shopId: null,
      errors: [],
      haveRedeemsList: ["All", "No", "Yes"],
      haveRedeems: "",
    };
  },
  watch: {
    pagination: {
      handler() {
        if (this.subscriptionApiInitiated) this.retrieveMemberships();
      },
      deep: true
    }
  },
  async created() {
    if (!this.currentUser.superAdmin) return;
    this.loading = true;
    this.$log.debug("await init sub service - start");
    await SubscriptionApiService.init();
    this.$log.debug("await init sub service - end");
    this.subscriptionApiInitiated = true;
    this.retrieveMemberships();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Memberships"}]);
  },
  methods: {
    parseDate(timestamp) {
      return timestamp !== null ? moment
          .utc(timestamp)
          .local()
          .format("yyyy-MM-DD HH:mm:ss") : "";
    },

    getRequestParams(
        shopId,
        shopName,
        activeSubscriptions,
        haveRedeems,
        createdDateFrom,
        createdDateTo,
        validDateFrom,
        validDateTo,
        subscriptionInterval
    ) {
      let params = {};

      if (shopId) {
        params["shopId"] = shopId;
      }

      if (shopName) {
        params["shopName"] = shopName;
      }

      if (subscriptionInterval) {
        params["subscriptionInterval"] = subscriptionInterval;
      }

      if (activeSubscriptions && (activeSubscriptions !== this.$i18n.t("common.all"))) {
        if (activeSubscriptions === this.$i18n.t("common.yes")) {
          params["active"] = true;
        } else if (activeSubscriptions === this.$i18n.t("common.no")) {
          params["active"] = false;
        }
      }

      if (haveRedeems && (haveRedeems !== this.$i18n.t("common.all"))) {
        if (haveRedeems === this.$i18n.t("common.yes")) {
          params["haveRedeems"] = true;
        } else if (haveRedeems === this.$i18n.t("common.no")) {
          params["haveRedeems"] = false;
        }
      }

      if (createdDateTo) {
        params["createdDate"] = createdDateTo.getTime();
      }

      if (validDateTo) {
        params["validTo"] = validDateTo.getTime();
      }
      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["sort"] = sort;
      params["page"] = this.pagination.page;
      params["size"] = this.pagination.itemsPerPage;

      return params;
    },
    clearSearch() {
      this.shopId = "";
      this.shopName = "";
      this.activeSubscriptions = "";
      this.haveRedeems = "";
      this.subscriptionInterval = "";

    },
    retrieveMemberships() {
      const params = this.getRequestParams(
          this.shopId,
          this.shopName,
          this.activeSubscriptions,
          this.haveRedeems,
          this.createdDateFrom,
          this.createdDateTo,
          this.validDateFrom,
          this.validDateTo,
          this.subscriptionInterval
      );
      this.$log.debug("params: ", params);
      return new Promise(resolve => {
        this.loading = true;
        SubscriptionApiService.query("/MembershipsAdminPanel",
            params
        ).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("MembershipsWithDetails response: ", response.data);
          this.memberships = response.data.content;
          this.totalMemberships = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title = this.totalMemberships;
          this.$store.dispatch(SET_BREADCRUMB, [{title: "Memberships: " + title}]);
          resolve();
        });
      });
    }
  }
};


</script>
<style>
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}

.v-icon.v-icon {
  font-size: 24px !important;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}

tbody tr {
  width: 100%;
}

.filter {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  background-color: aliceblue;
  border: 1px solid brown;
}

.search {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.search-item {
  justify-content: center;
  display: flex;
}

</style>
